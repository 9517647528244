<script>
// import SelectGroup from "@/components/forms/select-group";

export default {
    components: {
        // SelectGroup
	},
    data() {
        return {
            tutor_id: null,
            data: [],
            confirmedCallback: null,
            show: false,
        };
    },
    methods: {
        showModal(data, confirmedCallback) {
            this.data = data;
            this.confirmedCallback = confirmedCallback;
            this.show = true;
            console.log('DATA', data)
        },

        closeModal() {
            this.show = false;
        },
        hideModel() {
            this.$emit('CloseBtn', true);
        },
        updateTutor() {
            // console.log('this', this.tutor_id)
            // this.$emit('changeTutor', this.tutor_id);
            this.closeModal();
        },

        formatApplyDate(dateString) {
            const date = new Date(dateString);

            const options = {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
                timeZone: 'Asia/Singapore'
            };

            const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

            return formattedDate.replace('at', '');
        },

        formatCommencementDate(dateString) {
			const date = new Date(dateString);
			const options = { year: 'numeric', month: 'long', day: '2-digit' };
			return new Intl.DateTimeFormat('en-GB', options).format(date);
		},

        approve(data) {
            this.$emit('approve', data);
        }
    },
    props: {
        preventClose: {
            default: false,
        }
    }
}
</script>

<template>
    <b-modal v-model="show" scrollable hide-header @hide="hideModel" :no-close-on-backdrop="preventClose">
        <h1>Application Details</h1>

        <p class="applicant_name_label">Applicant name</p>
        <p class="applicant_name_desc">
            <router-link class="mr-2 text-primary tutor_link"
                :to="{ name: 'Tutor View', params: { id: data.tutor_id } }" target="_blank">
                {{ data.name }}
            </router-link>
        </p>

        <label>Preferred Budget</label>
        <p class="desc">${{ data.budget }} per hour</p>

        <div class="row">
            <div class="col-6">
                <label>Applied date</label>
                <p class="desc">{{ formatApplyDate(data.created_at) }}</p>
            </div>

            <div class="col-6">
                <label>Earliest commencement date</label>
                <p class="desc">{{ formatCommencementDate(data.latest_commencement_date) }}</p>
            </div>
        </div>

        <hr>

        <label>Availability</label>
        <p class="desc">{{ data.availability ?? '-' }}</p>

        <label>Why should I be chosen?</label>
        <p class="desc">{{ data.why_should_be_chosen ?? '-' }}</p>

        <template #modal-footer v-if="data.status == 'pending'">
            <button type="submit" class="close__btn" v-on:click="closeModal">Close</button>
            <button type="button" class="save__btn" @click="approve(data)">Approve application</button>
        </template>
        <template #modal-footer v-else>
            <button type="submit" class="got_it__btn" v-on:click="closeModal">Got it</button>
        </template>
    </b-modal>
</template>

<style scoped>
h1 {
    color: #000;
    font-family: Nunito;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 48px;
}

p {
    margin-bottom: 0;
}

.applicant_name_label {
    color: #505D69;
    font-family: Nunito;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 6px;
}

.applicant_name_desc {
    color: #004AAD;
    font-family: Nunito;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 20px;
}

label {
    color: #505D69;
    font-family: Nunito;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 6px;
}

.desc {
    color: #505D69;
    font-family: Nunito;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
}

.close__btn {
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    background: #F2F2F2;
    padding: 9px 20px;
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    color: #505D69;
}

.save__btn {
    float: right;
    border: 1px solid #219653;
    border-radius: 6px;
    background: #219653;
    padding: 9px 20px;
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.got_it__btn {
    float: right;
    border: 1px solid #004AAD;
    border-radius: 6px;
    background: #004AAD;
    padding: 9px 20px;
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}
</style>